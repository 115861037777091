/**
 * 按钮权限指令
 */
import store from "@/store"
const fn = (el, binding) => {
  const { value } = binding
  const list = store?.getters?.permissionList
  if (value && !list.includes(value)) {
    el.style.display = 'none'
  } else {
    el.style.display = 'inherit'
  }
}

// export default {
//   inserted: fn,
//   componentUpdated: fn
// }

export default fn
