<template>
  <div class="notice-content" v-scroll="load">
    <slot></slot>
    <p v-if="!loading && !noMore" @click="load" class="scroll-btn">点击加载更多</p>
    <p class="scroll-tips" v-if="loading">加载中...</p>
    <template>
      <!-- <p class="scroll-tips" v-if="noMore">没有更多了</p> -->
      <el-empty description="无未读消息" v-if="!total"></el-empty>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      total: 0,
      pageNo: 1
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    defaultPageSize: {
      type: Number,
      default: 10
    }
  },
  computed: {
    noMore () {
      const totalPage = Math.ceil(this.total / this.defaultPageSize)

      return this.pageNo > 1 && this.pageNo > totalPage && !this.loading
    }
  },
  directives: {
    scroll: {
      inserted(el, binding) {
        const { value } = binding
        el.addEventListener('scroll', () => {
          const { scrollTop, offsetHeight } = el
          const { scrollHeight } = el.firstChild
          if (scrollHeight - offsetHeight - scrollTop < 30) {
            value()
          }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.load())
  },
  destroyed() {
    this.reset()
  },
  methods: {
    completeByTotal(list, total) {
      const arr = [...this.value, ...list]
      this.$emit('input', arr)
      this.total = total || 0
      this.loading = false
    },
    load () {
      if (this.loading || this.noMore) return false
      this.loading = true
      this.$emit('query', this.pageNo, this.defaultPageSize)
      this.pageNo++
    },
    reload() {
      this.pageNo = 1
      this.$emit('query', this.pageNo, this.defaultPageSize)
    },
    reset() {
      this.pageNo = 1
      this.$emit('input', [])
    }
  }
}
</script>

<style lang="scss" scoped>

  .notice-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      background-color: #f5f5f5;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #c8c8c8;
    }
  }

  .scroll-tips {
    text-align: center;
    margin: 15px 0;
  }
  .scroll-btn {
    cursor: pointer;
    // padding: 15px 0;
    margin: 15px 0;
    text-align: center;
  }
</style>
