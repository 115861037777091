<template>
  <scroll-bar>
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="$route.path"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      :collapse="isCollapse">
      <sidebar-item :routes="routes"></sidebar-item>
    </el-menu>
  </scroll-bar>
</template>

<script>
import ScrollBar from '@/components/ScrollBar/index.vue'
import SidebarItem from './SidebarItem.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  components: {
    SidebarItem,
    ScrollBar
  },
  mounted () {},
  computed: {
    ...mapGetters([
      'routers',
      'sidebar'
    ]),
    routes () {
      return this.routers
    },
    isCollapse () {
      return !this.sidebar.opened  
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
