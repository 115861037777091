import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
// import './lib/element'
import '@/icons/index.js'
import '@/permission.js'
import global from '@/lib/global'
// import echarts from '@/lib/Echarts'

Vue.config.productionTip = false
Vue.prototype.$global = global

echarts.registerLocale('zh-cn')
Vue.prototype.$Echarts = echarts

import Directives from './directives'
Vue.use(Directives)

/**
 * v-if 实现用户角色权限控制
 * 组件使用hasPermission实现权限控制, 普通元素使用v-permission
 * @param {*} value 
 * @returns Boolean
 */
Vue.prototype.hasPermission = (value) => {
  const list = store?.getters?.permissionList
  return value && list.includes(value)
}

Vue.prototype.$isEmpty = (val) => {
  return val === '' || val === null || val === undefined
}

console.log('当前环境', process.env.NODE_ENV);
// console.log('后端地址', process.env.VUE_APP_SERVE);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
