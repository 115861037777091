<template>
  <div class="nav">
    <el-menu class="navbar" mode="horizontal">
      <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
      <breadcrumb></breadcrumb>
      <el-dropdown class="avatar-container" trigger="click" @command="showDialog">
        <div class="avatar-wrapper">
          <el-badge :value="navBadge" class="notice-badge"
            :max="99" :hidden="!navBadge || navBadge == '0'"
            @click.stop.native="handleClickNotice"
            v-if="device === 'desktop'">
            <svg-icon icon-class="notice" class="notice"></svg-icon>
          </el-badge>
          <!-- <img @click.stop="$router.push({ path: '/mms/vip' })"
            :src="vipInfo.levelIcon" class="user-level"> -->
          <div class="user-avatar">
            <img :src="userInfo.icon" />
            <img class="level-icon" :src="userInfo.levelIcon" v-if="userInfo.levelIcon">
          </div>
          <i class="el-icon-caret-bottom"></i>
          <div class="username">{{ userInfo.nickName }}</div>
        </div>
        <el-dropdown-menu class="user-dropdown" slot="dropdown">
          <!-- <router-link class="inlineBlock" to="/"></router-link> -->
          <el-dropdown-item disabled>
            {{ userInfo.nickName }}
          </el-dropdown-item>
          <router-link to="/user">
            <el-dropdown-item divided :command="1">
              个人设置
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided :command="2" v-if="device === 'mobile'">
            消息
          </el-dropdown-item>
          <el-dropdown-item divided :command="3">
            修改密码
          </el-dropdown-item>
          <!-- <el-dropdown-item divided :command="4">
            忘记密码
          </el-dropdown-item> -->
          <el-dropdown-item divided :command="0">
            <span>退出系统</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>


    <!-- 修改密码 -->
    <!-- <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="400px">
      <el-form
        :model="info"
        :rules="rules"
        label-width="70px">
        <el-form-item label="旧密码">
          <el-input
            type="password"
            placeholder="请输入旧密码"
            v-model="info.oldPassword"
            clearable
            show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            type="password"
            placeholder="请输入新密码"
            v-model="info.newPasswordFirst"
            clearable
            show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
            type="password"
            placeholder="请输入新密码"
            v-model="info.newPasswordSecond"
            clearable
            show-password>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdatePassword">确 定</el-button>
      </span>
    </el-dialog> -->

    <reset-pwd :dialogVisible.sync="dialogVisible"></reset-pwd>
    <change-pwd @forget="showDialog(4)"></change-pwd>

    <!-- 未读消息列表 -->
    <notice></notice>
    
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import notice from './Notice/notice.vue'
import resetPwd from './changePwd/resetPwd.vue'
import changePwd from './changePwd/changePwd.vue'
import { mapGetters } from 'vuex'

import { getPersonal, updatePassword } from '@/api/login/index'

const defaultInfo = {
  oldPassword: null,
  newPasswordFirst: null,
  newPasswordSecond: null,
}

export default {
  data () {
    return {
      dialogVisible: false,
      info: Object.assign({}, defaultInfo),
      rules: {},
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'sidebar', 'navBadge', 'device'])
  },
  mounted () {
    // getPersonal().then(res => {
    //   this.info.username = res.data.username
    // })
  },
  methods: {
    toggleSideBar () {
      // this.sidebar.opened = !(this.sidebar.opened)
      this.$store.dispatch('ToggleSideBar')
    },
    logout () {
      this.$store.dispatch('LogOut').then(res => {
        this.$message.success(res.message)
        location.reload()
      }).catch(error => {
        this.$message.error(error)
      })
    },
    showDialog(command) {
      // if (command) {
      //   this.dialogVisible = true
      // }
      switch(command) {
        case 1:
          break;
        case 2:
          this.$store.dispatch('toggleNavbarNotice', true)
          break;
        case 3:
          this.$store.dispatch('toggleChgDefPwdDailog', true)
          break;
        case 4:
          this.dialogVisible = true
          break;
        case 0:
          this.logout()
          break;
        default:
          return
      }
    },
    handleUpdatePassword() {
      this.$confirm('确认要修改密码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updatePassword(this.info).then(res => {
          this.info = Object.assign({}, defaultInfo)
          this.dialogVisible = false
          this.$message.success('密码修改成功，请重新登录')
          this.logout()
        })
      })
    },
    handleToRechange() {
      this.$router.push({ path: '/vip' })
    },
    handleClickNotice() {
      this.$store.dispatch('toggleNavbarNotice', true)
    }
  },
  components: {
    Breadcrumb,
    Hamburger,
    notice,
    resetPwd,
    changePwd
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  // line-height: 50px;
  border-radius: 0px !important;
  border-bottom: solid 1px #e6e6e6 !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      display: flex;
      align-items: center;
      .username {
        margin: 0 5px;
        line-height: 1.4;
      }
      .notice-badge {
        margin-right: 40px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 20px;
          z-index: 0;
          background-color: #C8C8C8;
        }
      }
      .notice {
        font-size: 20px;
      }
      .user-level {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-left: 20px;
      }
      .user-avatar {
        width: 40px;
        height: 40px;
        // padding-left: 40px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
        .level-icon {
          position: absolute;
          bottom: -2px;
          right: -4px;
          width: 15px;
          height: 15px;
          img {
            border-radius: 0;
          }
        }
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
</style>
