// import mqtt from 'mqtt'

const defaultConnect = {
  username: "yxd",
  password: "ptcss@520",
  clean: true, // 保留会话
  cleanSession : false,
  keepAlive: 60,
  // clientId: 'web_admin_用户名_时间戳',
  clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
  connectTimeout: 4000
}

const defaultSubscribe = {
  topic: [],  //需要传入数组的包含订阅的名称
  qos: 0
}

class HandleMQTT {
  /**
   * 
   * @param {String} url mqtt地址
   * @param {Object} connect 配置参数
   * @param {Object} subscribe 
   */
  constructor(url, subscribe, connect) {
    this.connectUrl = url
    this.connect = Object.assign(defaultConnect, connect)
    this.subscription = subscribe || defaultSubscribe
    this.client = null
  }

  // 连接MQTT
  createConnect() {
    // console.log(this.connect.clientId);
    return new Promise((resolve, reject) => {
      // 配置链接
      try {
        this.client = mqtt.connect(this.connectUrl, this.connect)
        this.client.on('connect', (event) => {
          console.log('成功连接服务器', event);
          // 订阅
          const { topic, qos } = this.subscription
          this.client.subscribe(topic, { qos }, err => {
            if (!err) {
              console.log("订阅成功");
            } else {
              console.log('消息订阅失败！')
            }
          })
        })
        
        //重新连接
        this.reconnect()
        //是否已经断开连接
        this.mqttError()
        //监听获取信息
        // this.getMessage()

        resolve()
      } catch (error) {
        console.log(error);
        reject()
      }
    })
  }

  //发布消息@topic主题  @message发布内容
  publish(topic,message) {
    if (!this.client.connected) {
      console.log('客户端未连接')
      return
    }
    this.client.publish(topic,message,{qos: 1},(err) => {
      if(!err) {
        console.log('主题为'+topic+ "发布成功")
      }
    })
  }

  /**
   * (topic, message) => {
      if(message) {
        console.log('收到来着',topic,'的信息',message.toString())
        // const res = JSON.parse(message.toString())
        // console.log(res);
        // switch(topic) {
        //    case 'top/#' :
        //      this.msg = res
        //      break;
        //    case 'three/#' :
        //      this.msg = res
        //      break;
        //    case 'three/#' :
        //      this.msg = res
        //      break;
        //    default:
        //      return
        //      this.msg = res
        //  }
        //  this.msg = message
        // let name = topic.split('/')[1]
      }
    }
   */
  //监听接收消息
  getMessage(fn = () => {}) {
    this.client.on("message", fn);
  }

  //监听服务器是否连接失败
  mqttError() {
    this.client.on('error',(error) => {
      console.log('连接失败：',error)
      this.client.end()
    })
  }

  //监听服务器重新连接
  reconnect() {
    this.client.on('reconnect', (error) => {
      console.log('正在重连:', error)
    });
  }

  // 断开链接
  unconnect() {
    this.client.end()
    this.client = null
    console.log('服务器已断开链接');
  }

  // 取消订阅
  unsubscribe(topic) {
    this.client.unsubscribe(topic, {}, (err) => {
      if (err) {
        console.log(`取消订阅主题: ${topic}失败`);
      } else {
        console.log(`取消订阅主题: ${topic}成功`);
      }
    })
  }
}

export default HandleMQTT
