<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="handleClosed">

    <el-form
      ref="changeForm"
      :model="pwdData"
      :rules="rules"
      label-width="90px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input
          type="password"
          placeholder="请输入旧密码"
          v-model="pwdData.oldPassword"
          clearable
          show-password>
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPasswordFirst">
        <el-input
          type="password"
          placeholder="请输入新密码"
          v-model="pwdData.newPasswordFirst"
          clearable
          show-password>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="newPasswordSecond">
        <el-input
          type="password"
          placeholder="请输入新密码"
          v-model="pwdData.newPasswordSecond"
          clearable
          show-password>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="text-align:right;">
      <el-button type="text" style="padding:0;" @click="handleForgetPwd">忘记密码?</el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirmClick" :loading="buttonStatus">
        确 定
      </el-button>
    </span>

  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { updatePersonalPassword } from '@/api/user/index'
import { MessageBox } from 'element-ui'

export default {
  data() {
    return {
      // visible: false,
      pwdData: {
        oldPassword: null,
        newPasswordFirst: null,
        newPasswordSecond: null
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPasswordFirst: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        newPasswordSecond: [{ required: true, message: '请确认新密码', trigger: 'blur' }]
      },
      buttonStatus: false
    }
  },
  computed: {
    ...mapGetters(['chgDefPwdDialog']),
    visible: {
      get() {
        return this.chgDefPwdDialog
      },
      set(value) {
        this.$store.dispatch('toggleChgDefPwdDailog', value)
      }
    }
  },
  methods: {
    ...mapActions(['toggleChgDefPwdDailog']),
    handleConfirmClick() {
      this.$refs.changeForm.validate(valid => {
        if(valid) {
          this.buttonStatus = true
          updatePersonalPassword(this.pwdData).then(res => {
            this.$message.success(res.message)
            this.$store.dispatch('toggleChgDefPwdDailog', false)
            MessageBox.confirm('您已被登出，修改密码后需要重新登录！', {
              title: '温馨提示',
              confirmButtonText: '重新登录',
              // cancelButtonText: '取消',
              showCancelButton: false,
              type: 'warning'
            }).then(() => {
              this.$store.dispatch('FedLogOut').then(() => {
                location.reload()// 为了重新实例化vue-router对象 避免bug
              })
            })
          }).finally(this.buttonStatus = false)
        }
      })
    },
    handleForgetPwd() {
      this.handleClosed()
      setTimeout(() => this.$emit('forget') , 300)
    },
    handleClosed() {
      // this.$emit('update:dialogVisible', false)
      this.$store.dispatch('toggleChgDefPwdDailog', false)
      this.$refs.changeForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
