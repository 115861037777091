// 接口地址
const baseURL = process.env.VUE_APP_SERVE
// 微信授权回调域
const wechatRedirectUri = process.env.VUE_APP_WECHAT_REDIRECT_URI
// 绑定微信获取用户信息的回调域
const bindWechatRedirectUri = process.env.VUE_APP_BIND_WECHAT_REDIRECT_URI
// 微信APPID
const wechatAppid = process.env.VUE_APP_WECHAT_APPID
// mqtt地址
const mqttUrl = process.env.VUE_APP_MQTT_URL

export default {
  baseURL,
  wechatRedirectUri,
  bindWechatRedirectUri,
  wechatAppid,
  mqttUrl
}
