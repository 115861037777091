const dialog = {
  // namespaced: true,
  state: {
    hasBindCellPhone: false,       // 是否绑定过手机号
    chgDefPwdDialog: false,       // 修改默认密码弹窗
    navbarNotice: false,          // 导航栏通知列表
  },
  mutations: {
    SET_HAS_BIND_CELL_PHONE(state, data) {
      state.hasBindCellPhone = data
    },
    SET_CHG_DEF_PWD_DIALOG(state, data) {
      state.chgDefPwdDialog = data
    },
    SET_NAVBAR_NOTICE(state, data) {
      state.navbarNotice = data
    }
  },
  actions: {
    toggleBindCellPhone({ commit }, data) {
      commit('SET_HAS_BIND_CELL_PHONE', data)
    },
    toggleChgDefPwdDailog({ commit }, data) {
      commit('SET_CHG_DEF_PWD_DIALOG', data)
    },
    toggleNavbarNotice({ commit }, data) {
      commit('SET_NAVBAR_NOTICE', data)
    }
  }
}

export default dialog
