import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
// import Vue from 'vue'
import { getToken } from '@/utils/auth'
// import qs from 'qs' // 引入 node中自带的qs模块（数据格式转换
import global from '@/lib/global'
import store from '../store'
import router from '../router'

// 配置axios的属性
// axios.defaults.timeout = 6000 // 请求超时时间1分钟
// axios.defaults.baseURL = baseURL // 你的接口地址
// axios.defaults.responseType = 'json'
axios.defaults.withCredentials = false // 是否允许带cookie这些
// axios.defaults.withCredentials = true // 允许跨域请求Cookie
// axios.defaults.headers['Content-Type'] = 'application/json'
// axios.defaults.headers['Accept'] = 'application/json'
// axios.defaults.headers['Authorization'] = getToken() || ''
// axios.defaults.headers['uid'] = localStorage.getItem('uid')
// .....

// 创建axios实例
const service = axios.create({
  // baseURL: 'process.env.BASE_API', // api的base_url
  baseURL: global.baseURL,
  timeout: 15000, // 请求超时时间
  withCredentials: false, // 设置为 false，表示不携带 cookie
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Authorization'] = getToken() || ''
  // if (config.method === 'post' && !config.hasOwnProperty('uploadFile')) {
  //   /* 数据转换: axios post方式默认是json格式提交数据，如果使用application/x-www-form-urlencoded数据格式提交，要用qs.stringify()进行转换,个人建议不在拦截器中全局配置，因为不够灵活，还有一点是，如果
  // 设置了重新请求的配置，那么重新请求时，请求体中的config里面的传参就会被再次进行qs.stringify()转
  // 换，会使得参数丢失，造成请求失败。 */
  //   config.data = qs.stringify(config.data)
  // }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    const res = response.data
    if (res.code != 200) {
      const errIndex = ['401', '403', '404', '500']
      if (errIndex.includes(res.code)) {
        Message({
          message: res.code + ': ' + res.message,
          type: 'error',
          duration: 3 * 1000
        })
      } else {
        Message({
          message: res.code + ': ' + res.message,
          type: 'warning',
          duration: 3 * 1000
        })
      }

      // 401:未登录;
      if (res.code == 401) {
        const timer = setTimeout(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        }, 3000)
        MessageBox.confirm('您已被登出，3秒后回到登录页，请重新登录！', {
          title: '温馨提示',
          confirmButtonText: '重新登录',
          // cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          clearTimeout(timer)
          store.dispatch('FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      } else if (res.code == '10005' || res.code == '10006') {
        MessageBox.confirm(res.message, {
          title: '温馨提示',
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push({ path: '/feedback/message' })
        })
      }
      return Promise.reject(res.message)
    } else {
      return response.data
    }
  },
  error => {
    console.log('err:' + error)// for debug
    Message({
      message: '请检查你的网络哦，失联了',
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
