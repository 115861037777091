import request from '../index'

// 用户账号密码登录
export const login = (data) => {
  return request({
    url: '/manager/webAdmin/login',
    method: 'post',
    data
  })
}

// 手机号登录
export const loginWithVCode = (data) => {
  return request({
    url: '/manager/webAdmin/loginWithVCode',
    method: 'POST',
    data
  })
}

// 发送验证码：用于登录
export const sendVCode = (data) => {
  return request({
    url: '/manager/webAdmin/sendVCode',
    method: 'POST',
    data
  })
}

// 用户登出
export const logout = () => {
  return request({
    url: '/manager/webAdmin/logout',
    method: 'POST'
  })
}

// 获取当前登录用户信息
export const info = () => {
  return request({
    url: '/manager/webAdmin/info',
    method: 'GET'
  })
}

// 根据用户名或姓名分页搜索用户列表
export const fetchList = (params) => {
  return request({
    url: '/manager/webAdmin/list',
    method: 'GET',
    params
  })
}

// 获取当前登录用户个人信息
export const getPersonal = () => {
  return request({
    url: '/manager/webPersonal/info',
    method: 'GET'
  })
}

// 修改个人信息
export const updatePersonal = (data) => {
  return request({
    url: '/manager/webPersonal/update',
    method: 'POST',
    data
  })
}

// 修改当前个人用户密码
export const updatePassword = (data) => {
  return request({
    url: '/manager/webPersonal/updatePersonalPassword',
    method: 'POST',
    data
  })
}

// admin修改指定职员用户密码
export const adminUpdatePassword = (data) => {
  return request({
    url: '/manager/webAdmin/updatePassword',
    method: 'POST',
    data
  })
}

// 钻石会员修改指定职员用户密码
export const updateMemberPassword = (data) => {
  return request({
    url: '/manager/webAdmin/updateMemberPassword',
    method: 'POST',
    data
  })
}

// 微信扫码登录时，前端首页获取用户认证token
export const loginWithWechat = (params) => {
  return request({
    url: '/manager/webAdmin/loginWithWechat',
    method: 'POST',
    params
  })
}

export const wechatCallback = (params) => {
  return request({
    url: '/manager/webAdmin/wechatCallback',
    method: 'GET',
    params
  })
}

// 获取key
export const getPublicKey = () => {
  return request({
    url: '/manager/webAdmin/publicKey',
    method: "GET"
  })
}