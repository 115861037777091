<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    width="600px"
    ref="dialog"
    @closed="handleDialogClosed">
    <div slot="title" class="dialog-title">
      <h3>{{ details?.title }}</h3>
      <p>{{ details?.subtitle }}</p>
    </div>

    <div class="msg-container">
      <div class="msg-content" v-html="details?.content"></div>
      <div class="t">{{ details?.createDt | formatDateTime }}</div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="visible = false">确 定</el-button>
      <!-- <el-button type="primary" @click="onClick">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { formatDate } from '@/utils/date'
import { getNotifyInfo } from '@/api/user/notice'

const defaultDetails = {
  title: null,
  subtitle: null,
  content: null,
  createDt: null,
}

export default {
  data() {
    return {
      details: Object.assign({}, defaultDetails)
    }
  },
  computed: {
    visible: {
      get() {
        return this.dialogVisible
      },
      set(newVal) {
        this.$emit('update:dialogVisible', newVal)
      }
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    noticeId: {
      type: Number,
      default: null
    },
    msgDetails: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    // msgDetails(newVal) {
    //   this.details = newVal
    // },
    dialogVisible(newVal) {
      if (newVal) {
        if (!this.$isEmpty(this.noticeId)) {
          this.getMsgDetails()
        } else {
          this.details = this.msgDetails
        }
      }
    }
  },
  filters: {
    formatDateTime (value) {
      if (!value) return 'N/A'
      let date = new Date(value)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  methods: {
    getMsgDetails() {
      getNotifyInfo(this.noticeId).then(res => {
        this.details = res.data
      })
    },
    handleDialogClosed() {
      // this.details = Object.assign({}, defaultDetails)
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-title {
    h3 {
      margin-bottom: 3px;
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .msg-content {
    font-size: 18px;
    line-height: 1.5;
  }
  .t {
    font-size: 16px;
    margin-top: 17px;
    // text-align: right;
  }
</style>
