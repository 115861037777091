<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { Updater, hashUpdater } from '@/utils/updater'
import HandleMQTT from '@/utils/mqtt'
import { mapGetters } from 'vuex'
import global from '@/lib/global'
import { Notification } from 'element-ui'

import store from '@/store'
import router from '@/router'
import { resetRouter } from '@/router'

export default {
  data() {
    return {
      updater: null,
      mqtt: null
    }
  },
  computed: {
    ...mapGetters(['username', 'userInfo'])
  },
  watch: {
    // username(newVal) {
    //   if (newVal) this.initMQTT()
    // },
    // userInfo: {
    //   // 获取到用户数据后初始化MQTT
    //   handler(newVal) {
    //     if (newVal.id && !this.mqtt) {
    //       this.initMQTT()
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') return false
    
    this.updater = new hashUpdater({
      time: 1000 * 60
    })
    this.updater.on('update', () => {
      console.log('更新');
      this.$msgbox.confirm('系统已更新，请重新登录！', {
        confirmButtonText: '重新登录',
        // cancelButtonText: '取消',
        type: 'warning',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$store.dispatch('FedLogOut').then(() => {
          location.reload()
        })
      })
    })
    this.updater.on('no-update',() => {
      console.log('未更新');
    })

    // this.updater.init()
    // console.log(this.updater);
  },
  methods: {
    initMQTT() {
      let topicList = [
        `yxd/dev/web/admin/notice/unread/${this.userInfo?.id}`,             // nav消息数量
        `yxd/dev/web/admin/member/recharge/success/${this.userInfo?.id}`,   // 支付结果
        `yxd/dev/web/admin/member/banding/success/${this.userInfo?.id}`,    // 成功绑定手机
        `yxd/dev/web/admin/member/invite/${this.userInfo?.id}`,             // 邀请
      ]
      if (process.env.NODE_ENV === 'production') {
        topicList = [
          `yxd/prod/web/admin/notice/unread/${this.userInfo?.id}`,              // nav消息数量
          `yxd/prod/web/admin/member/recharge/success/${this.userInfo?.id}`,   // 支付结果
          `yxd/prod/web/admin/member/banding/success/${this.userInfo?.id}`,    // 成功绑定手机
          `yxd/prod/web/admin/member/invite/${this.userInfo?.id}`,             // 邀请
        ]
      }
      this.mqtt = new HandleMQTT(global.mqttUrl, {
        topic: topicList,
        qos: 0
      }, { clientId: `web_admin_${this.userInfo?.id}_${new Date().getTime()}` })
      this.mqtt.createConnect().then(res => {
        this.mqtt.getMessage((topic, message) => {
          if (message) {
            console.log('收到来着',topic,'的信息',message.toString())
            const { content } = JSON.parse(message.toString())
            switch(topic) {
              case topicList[0]:
                this.$store.dispatch('setNavBadge', content)
                break;
              case topicList[1]:
                // console.log('充值topic');
                Notification({
                  type: 'success',
                  title: '充值成功',
                  message: content,
                  offset: 40
                })
                store.dispatch('GetInfo').then(res => {
                  let { menus, username } = res.data
                  // 初始化路由
                  resetRouter()
                  store.dispatch('GenerateRoutes', { menus, username }).then(() => {
                    // router.addRoutes(store.getters.addRouters)     // addRoutes 即将淘汰
                    store.getters.addRouters.forEach(routerItem => {
                      router.addRoute(routerItem)
                    })
                  })
                }).catch(err => {
                  store.dispatch('FedLogOut').then(() => {
                    Message.error(err || 'Verification failed, please login again')
                    next({ path: '/' })
                  })
                })
                break;
              case topicList[2]:
                // 成功绑定手机
                // const data = JSON.parse(message.toString())
                Notification({
                  type: 'success',
                  title: '绑定成功',
                  message: '绑定成功手机号码绑定成功',
                  offset: 40
                })

                break;
              case topicList[3]:
                // 邀请
                break;
              default:
                return
            }
          }
        })
      })
    },

  },
}
</script>

<style lang="scss">
  // 清除input的type属性为number时的默认样式
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none !important;
    -webkit-appearance: none !important;
  }
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  // element连级选择器清除滚动条
  // .el-cascader-menu .el-scrollbar__wrap  {
  //   overflow: hidden !important;
  //   margin-bottom: 0 !important;
  //   margin-right: 0 !important;
  // }

  // 设置Message组件的层级
  .el-message, .el-notification {
    z-index: 9999 !important;
  }

  .btn-groups {
    // margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    button {
      margin-left: 0 !important;
    }
    &.singer {
      display: block;
    }
  }
</style>
