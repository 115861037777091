import request from '../index'

// 获取指定用户的角色
export const getUserRoleByID = (id) => {
  return request({
    url: '/manager/webAdmin/role/' + id,
    method: 'GET'
  })
}

// 修改职员用户状态
export const updateUserStatus = (id, data) => {
  return request({
    url: '/manager/webAdmin/updateStatus/' + id,
    method: 'POST',
    params: data
  })
}

// 给职员用户分配角色
export const roleUpdate = (data) => {
  return request({
    url: '/manager/webAdmin/role/update',
    method: 'POST',
    data
  })
}

// 编辑用户信息
export const updateAdmin = (id, data) => {
  return request({
    url: '/manager/webAdmin/update/' + id,
    method: 'POST',
    data
  })
}

// 添加用户
export const createAdmin = (data) => {
  return request({
    url: '/manager/webAdmin/register',
    method: 'POST',
    data
  })
}

// 获取指定ID的用户信息
export const getUserInfo = (id) => {
  return request({
    url: '/manager/webAdmin/' + id,
    method: 'GET'
  })
}

// 获取角色相关用户
export const roleList = (id) => {
  return request({
    url: '/manager/role/listUser/' + id,
    method: 'GET'
  })
}

// 获取所有角色为业务员的用户
export const listAllSales = () => {
  return request({
    url: '/manager/webAdmin/listAllSales',
    method: 'GET'
  })
}

// 发送验证码：用于修改密码
export const sendVCodeForResetPW = (data) => {
  return request({
    url: '/manager/webAdmin/sendVCodeForResetPW',
    method: 'POST',
    data
  })
}

// 修改当前个人用户密码
export const updatePersonalPassword = (data) => {
  return request({
    url: '/manager/webPersonal/updatePersonalPassword',
    method: 'POST',
    data
  })
}

// 使用手机号验证码来重置密码(忘记密码)
export const resetPersonalPassword = (data) => {
  return request({
    url: '/manager/webPersonal/resetPersonalPassword',
    method: 'POST',
    data
  })
}

// 校验修改密码的校验码
export const checkVCodeForResetPW = (code) => {
  return request({
    url: '/manager/webAdmin/checkVCodeForResetPW/' + code,
    method: 'GET'
  })
}

// 发送验证码：用于绑定手机号
export const sendVCodeForBanding = (data) => {
  return request({
    url: '/manager/webAdmin/sendVCodeForBanding',
    method: 'POST',
    data
  })
}

// 用户绑定手机
export const bandingPhone = (data) => {
  return request({
    url: '/manager/webPersonal/bandingPhone',
    method: 'POST',
    data
  })
}

// 添加组员，发送验证码，随机4位数
export const sendVCodeForInvite = (data) => {
  return request({
    url: '/manager/webPersonal/sendVCodeForInvite',
    method: 'POST',
    data
  })
}

// 邀请组员
export const inviteMember = (data) => {
  return request({
    url: '/manager/webPersonal/inviteMember',
    method: 'POST',
    data
  })
}

// 同意成为对方组员，传入对方的账户
export const agreedBeMember = (data) => {
  return request({
    url: '/manager/webPersonal/agreedBeMember',
    method: 'POST',
    data
  })
}

// 用户绑定微信
export const bandingWechat = (params) => {
  return request({
    url: '/manager/webPersonal/bandingWechat',
    method: 'POST',
    params
  })
}

// 用户修改绑定的手机号
export const updateMyPhone = (data) => {
  return request({
    url: '/manager/webPersonal/updateMyPhone',
    method: 'POST',
    data
  })
}

// 主动退出组员，发送验证码，随机4位数
export const sendVCodeForExit = (data) => {
  return request({
    url: '/manager/webPersonal/sendVCodeForExit',
    method: 'POST',
    data
  })
}

// 退出对方组员
export const exitMember = (data) => {
  return request({
    url: '/manager/webPersonal/exitMember',
    method: 'POST',
    data
  })
}