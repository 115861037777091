<template>
  <el-dialog
    title="忘记密码"
    :visible.sync="visible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="handleClosed">
    <el-form
      ref="valid"
      :model="validate"
      :rules="phoneRules"
      label-width="140px"
      v-show="tabIndex === 0">
      <el-form-item label="当前绑定手机号：">
        <!-- <el-input
          type="number"
          placeholder="当前绑定手机号"
          v-model="validate.phone"
          disabled
          show-password>
        </el-input> -->
        {{ userInfo.username }}
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <el-input
          type="number"
          placeholder="请输入验证码"
          :maxlength="4"
          v-model="validate.code">

          <!-- !hasSent -->
          <template v-if="!hasSent">
            <el-button type="primary" class="valid-code" size="mini"
              slot="suffix" @click="handleCodeClick">
              获取验证码
            </el-button>
          </template>
          <template v-else-if="validTimeEnd">
            <el-button type="button" class="valid-code" slot="suffix" size="mini"
              @click="handleCodeClick">
              重新发送
            </el-button>
          </template>
          <template v-else>
            <div class="valid-code gray" slot="suffix">
              {{ validCd }}s后重试
            </div>
          </template>

        </el-input>
      </el-form-item>
    </el-form>
    <el-form
      ref="resetForm"
      :model="pwdData"
      :rules="rules"
      label-width="140px"
      v-show="tabIndex === 1">
      <el-form-item label="当前绑定手机号：">
        {{ userInfo.username }}
      </el-form-item>
      <el-form-item label="验证码：" prop="verifyCode">
        <el-input
          type="number"
          placeholder="请输入验证码"
          :maxlength="4"
          v-model="pwdData.verifyCode">

          <!-- !hasSent -->
          <template v-if="!hasSent">
            <el-button type="primary" class="valid-code" size="mini"
              slot="suffix" @click="handleCodeClick">
              获取验证码
            </el-button>
          </template>
          <template v-else-if="validTimeEnd">
            <el-button type="button" class="valid-code" slot="suffix" size="mini"
              @click="handleCodeClick">
              重新发送
            </el-button>
          </template>
          <template v-else>
            <div class="valid-code gray" slot="suffix">
              {{ validCd }}s后重试
            </div>
          </template>

        </el-input>
      </el-form-item>
      <!-- <el-form-item label="旧密码" prop="oldPassword">
        <el-input
          type="password"
          placeholder="请输入旧密码"
          v-model="pwdData.oldPassword"
          clearable
          show-password>
        </el-input>
      </el-form-item> -->
      <el-form-item label="新密码" prop="newPasswordFirst">
        <el-input
          type="password"
          placeholder="请输入新密码"
          v-model="pwdData.newPasswordFirst"
          clearable
          show-password>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="newPasswordSecond">
        <el-input
          type="password"
          placeholder="请输入新密码"
          v-model="pwdData.newPasswordSecond"
          clearable
          show-password>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClosed">取 消</el-button>
      <el-button type="primary" @click="handleConfirmClick" :loading="buttonStatus" :disabled="!hasSent">
        {{ buttonStr }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { resetPersonalPassword, checkVCodeForResetPW, sendVCodeForResetPW } from '@/api/user/index'
import { mapGetters } from 'vuex'
import { MessageBox } from 'element-ui'

const validateUsername = (rule, value, callback) => {
  if (!(/^(?:(?:\+|00)86)?1\d{10}$/.test(value))) {
    return callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}
const validateCode = (rule, value, callback) => {
  if (value.length < 1) {
    return callback(new Error('验证码不能为空'))
  } else {
    callback()
  }
}

export default {
  data() {
    return {
      visible: false,
      tabIndex: 1,
      validate: {
        phone: this.userInfo?.username,
        code: ''
      },
      phoneRules: {
        phone: [{
					required: true,
					trigger: 'blur',
          validator: validateUsername
				}],
        code: [{
					required: true,
					trigger: 'blur',
          validator: validateCode
				}]
      },
      pwdData: {
        verifyCode: null,
        // oldPassword: null,
        newPasswordFirst: null,
        newPasswordSecond: null
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPasswordFirst: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        newPasswordSecond: [{ required: true, message: '请确认新密码', trigger: 'blur' }]
      },
      hasSent: true,        // 是否已发送过验证码
      validCd: 60,
			validTimeEnd: true,			// 倒计时结束
			timer: null,
      buttonStatus: false
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    dialogVisible: {
      handler(newVal) {
        this.visible = newVal
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    buttonStr() {
      return this.tabIndex ? '确 定' : '下一步'
    }
  },
  methods: {
    handleConfirmClick() {
      if(this.tabIndex === 0) {
        // 发起请求校验验证码
        this.$refs.valid.validate(valid => {
          if (valid) {
            this.buttonStatus = true
            const { code } = this.validate
            checkVCodeForResetPW(code).then(res => {
              this.tabIndex = 1
            }).finally(() => this.buttonStatus = false)
          }
        })
      } else {
        // 发起请求修改密码
        this.$refs.resetForm.validate(valid => {
          if (valid) {
            this.buttonStatus = true
            resetPersonalPassword(this.pwdData).then(res => {
              this.$message.success(res.message)
              this.$emit('update:dialogVisible', false)
              MessageBox.confirm('您已被登出，修改密码后需要重新登录！', {
                title: '温馨提示',
                confirmButtonText: '重新登录',
                // cancelButtonText: '取消',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                this.$store.dispatch('FedLogOut').then(() => {
                  location.reload()// 为了重新实例化vue-router对象 避免bug
                })
              })
            }).finally(this.buttonStatus = false)
          }
        })
      }
    },
    handleCodeClick() {
      let phone = this.userInfo.username
      if (!(/^(?:(?:\+|00)86)?1\d{10}$/.test(phone))) return this.$message.warning('请输入正确的手机号码!')
      this.startCountDown()
      sendVCodeForResetPW({ telephone: phone }).then(res => {})
    },
    startCountDown(delay = 60) {
      this.validCd = delay
      this.validTimeEnd = false
      this.hasSent = true
      this.countDown()
    },
    countDown() {
      this.timer = setTimeout(() => {
        if (this.validCd <= 0) {
          this.validTimeEnd = true
          clearTimeout(this.timer)
          this.timer = null
          return false
        }
        this.validCd--
        this.countDown()
      }, 1000)
    },
    handleClosed() {
      this.$refs.valid.resetFields()
      this.$refs.resetForm.resetFields()
      this.$emit('update:dialogVisible', false)
      this.buttonStatus = false
      // setTimeout(() => this.tabIndex = 0, 300)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
