const app = {
  state: {
    sidebar: {
      opened: true,
      withoutAnimation: false
    },
    device: 'desktop',
    paginationLayout: 'total, sizes, prev, pager, next, jumper',
    navBadge: 0,                // 顶部导航栏通知标记数
    agreementChecked: false,    // 登录页同意协议按钮
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
      if (device === 'mobile') state.paginationLayout = 'prev, pager, next'
      else state.paginationLayout = 'total, sizes, prev, pager, next, jumper'
    },
    SET_NAV_BADGE: (state, number) => {
      state.navBadge = number
    }
  },
  actions: {
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setNavBadge({ commit }, number) {
      commit('SET_NAV_BADGE', number)
    },
  }
}

export default app
