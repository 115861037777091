<template>
  <el-dialog
    title="系统内测，限时免费"
    :visible.sync="dialog"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @click="handleClosed">

    <div class="tips">您还未绑定手机号，现在绑定可立即获取价值99元的使用特权，送完即止！</div>

    <login-with-phone @enter="handleConfirm" ref="phoneComponent"
      :hideBtn="true"
      :customSendCode="handleSendCode">
    </login-with-phone>
    
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClosed">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="btnStatus">绑 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import loginWithPhone from '@/views/login/components/loginWithPhone.vue'
import { mapGetters, mapActions } from 'vuex'
import { bandingPhone, sendVCodeForBanding } from '@/api/user/index'

export default {
  data() {
    return {
      formData: {},
      rules: {},
      btnStatus: false
    }
  },
  computed: {
    ...mapGetters(['hasBindCellPhone']),
    dialog: {
      get() {
        return this.hasBindCellPhone
      },
      set(value) {
        this.toggleBindCellPhone(value)
      }
    }
  },
  watch: {
    // hasBindCellPhone: {
    //   handler(newVal) {
    //     this.dialog = newVal
    //   },
    //   immediate: true
    // }
  },
  props: {

  },
  methods: {
    ...mapActions(['toggleBindCellPhone', 'GetInfo']),
    handleConfirm() {
      this.btnStatus = true
      const { formValidate, phoneForm } = this.$refs.phoneComponent
      formValidate().then(() => {
        bandingPhone({ mobilePhone: phoneForm.telephone, verifyCode: phoneForm.verifyCode }).then(res => {
          this.$message.success(res.message)
          this.btnStatus = false
          this.toggleBindCellPhone(false)
          this.GetInfo()
        }).catch(() => {
          this.btnStatus = false
        })
      })
    },
    handleSendCode(telephone) {
      sendVCodeForBanding({ telephone }).then(res => {
        const { startCountDown } = this.$refs.phoneComponent
        startCountDown()
      })
    },
    handleClosed() {
      this.toggleBindCellPhone(false)
    }
  },
  components: { loginWithPhone }
}
</script>

<style lang="scss" scoped>
  .tips {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
</style>
