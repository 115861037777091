import Cookies from 'js-cookie'

const TokenKey = 'loginToken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setCookie (key, value, expires) {
  return Cookies.set(key, value, { expires })
}

export function getCookie(key) {
  return Cookies.get(key)
}
