<template>
  <el-form
    autoComplete="on"
    :model="phoneForm"
    :rules="loginRules"
    ref="phoneForm"
    label-position="left">

      <!-- <div style="text-align: center">
        <svg-icon icon-class="logo" style="width: 56px;height: 56px;color: #409EFF"></svg-icon>
      </div>
      <h2 class="login-title color-main">葡淘成熟时</h2> -->
      <el-form-item prop="telephone">
        <el-input
          name="telephone"
          type="text"
          v-model="phoneForm.telephone"
          autoComplete="on"
          placeholder="请输入手机号">
          <span slot="prefix">
            <!-- <svg-icon icon-class="user" class="color-main"></svg-icon> -->
            +86
          </span>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode">
        <el-input
          name="verifyCode"
          type="text"
          v-model="phoneForm.verifyCode"
          @keyup.enter.native="handleEnter"
          placeholder="请输入验证码">
          <!-- <span slot="prefix">
            <svg-icon icon-class="password" class="color-main"></svg-icon>
          </span> -->
          <template v-if="!hasSent">
            <el-button type="text" class="valid-code"
              slot="suffix" @click="handleCodeClick">
              获取验证码
            </el-button>
          </template>
          <template v-else-if="validTimeEnd">
            <el-button type="text" class="valid-code" slot="suffix"
              @click="handleCodeClick">
              重新发送
            </el-button>
          </template>
          <template v-else>
            <div class="valid-code gray" slot="suffix">
              {{ validCd }}s后重试
            </div>
          </template>

        </el-input>
      </el-form-item>
      <el-form-item v-if="!hideBtn">
        <el-checkbox v-model="checked" class="checkbox" @change="handleCheckedChange">
          我已阅读并同意
          <a href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201712130944_39600.html" target="_blank">用户协议</a>
          和
          <a href="">隐私政策</a>
        </el-checkbox>
        <el-button
          type="primary"
          class="login-btn"
          :loading="loading"
          @click="handleLogin"
          :disabled="!hasSent">
          登录
        </el-button>
      </el-form-item>

  </el-form>
</template>

<script>
import { sendVCode } from '@/api/login/index'
import { setCookie, getCookie } from '@/utils/auth'

const validateUsername = (rule, value, callback) => {
  if (!(/^(?:(?:\+|00)86)?1\d{10}$/.test(value))) {
    return callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}
const validateCode = (rule, value, callback) => {
  if (value.length < 1) {
    return callback(new Error('验证码不能为空'))
  } else {
    callback()
  }
}

export default {
  data() {
    return {
			phoneForm: {
				telephone: '',
				verifyCode: ''
			},
      loginRules: {
				telephone: [{
					required: true,
					trigger: 'blur',
          validator: validateUsername
				}],
				verifyCode: [{
					required: true,
					trigger: 'blur',
          validator: validateCode
				}]
			},
      hasSent: false,        // 是否已发送过验证码
      validCd: 60,
			validTimeEnd: true,			// 倒计时结束
			timer: null,
      checked: false,
      loading: false
    }
  },
  mounted () {
    // agreement-checked
    const checked = localStorage.getItem('agreement-checked') || '{}'
    const { val } = JSON.parse(checked)
    this.checked = val
  },
  destroyed() {
    clearTimeout(this.timer)
    this.timer = null
  },
  props: {
    hideBtn: {
      type: Boolean,
      default: false
    },
    customSendCode: {
      type: Function,
      default: null
    }
  },
  methods: {
    formValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.phoneForm.validate(valid => {
          if (valid) {
            // console.log('表单验证成功');
            resolve(valid)
          } else {
            reject()
            this.$message.error('参数验证不合法！')
            return false
          }
        })
      })
    },
    loginWithPhone() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('loginWithPhone', this.phoneForm).then(res => {
          resolve()
          this.$message({
            type: 'success',
            message: res.message
          })
          this.$router.push({ path: '/' })
        }).catch(() => {
          reject()
        })
      })
    },
    handleCodeClick() {
      // this.$emit('reqCode')
      let { telephone } = this.phoneForm
      if (!(/^(?:(?:\+|00)86)?1\d{10}$/.test(telephone))) return this.$message.warning('请输入正确的手机号码!')
      
      if (this.customSendCode instanceof Function) {
        this.customSendCode(telephone)
      } else {
        this.startCountDown()
        sendVCode({ telephone }).then(res => {})
      }
    },
    handleEnter() {
      this.$emit('enter')
    },
    startCountDown(delay = 60) {
      this.validCd = delay
      this.validTimeEnd = false
      this.hasSent = true
      this.countDown()
    },
    countDown() {
      this.timer = setTimeout(() => {
        if (this.validCd <= 0) {
          this.validTimeEnd = true
          clearTimeout(this.timer)
          this.timer = null
          return false
        }
        this.validCd--
        this.countDown()
      }, 1000)
    },
    handleLogin() {
      this.formValidate().then(() => {
        if (!this.checked) return this.$message.warning('请阅读并勾选用户协议')
        this.loading = true
        this.loginWithPhone().finally(() => this.loading = false)
      })
    },
    resetForm() {
      this.$refs.phoneForm.resetFields()
    },
    handleCheckedChange(val) {
      localStorage.setItem('agreement-checked', JSON.stringify({val}))
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #DCDFE6;
    border-radius: 0;
  }
  .el-form-item {
    &:first-child ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 50px;
    }
    &:nth-of-type(2) ::v-deep .el-input--suffix .el-input__inner {
      padding-right: 80px;
    }
    &:last-child {
      margin-top: 30px;
    }
  }
  .valid-code {
    color: #409EFF;
    cursor: pointer;
    user-select: none;
    &.gray {
      color: gray;
      cursor: default;
    }
  }
  .login-btn {
    width: 100%;
    background-color: #409EFF;
    border: none;
    border-radius: 0;
  }
  .checkbox {
    margin: 50px 0 10px;
    &::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #333;
    }
    a {
      color: #409EFF;
    }
  }
</style>
