import store from '@/store/index'
import router from '@/router/index'
import HandleMQTT from '@/utils/mqtt'
import global from '@/lib/global'
import { Notification } from 'element-ui'
import { resetRouter } from '@/router'

const refreshUserInfo = () => {
  store.dispatch('GetInfo').then(res => {
    let { menus, username } = res.data
    // 初始化路由
    resetRouter()
    store.dispatch('GenerateRoutes', { menus, username }).then(() => {
      // router.addRoutes(store.getters.addRouters)     // addRoutes 即将淘汰
      store.getters.addRouters.forEach(routerItem => {
        router.addRoute(routerItem)
      })
    })
  }).catch(err => {
    store.dispatch('FedLogOut').then(() => {
      Message.error(err || 'Verification failed, please login again')
      next({ path: '/' })
    })
  })
}

const initMQTT = () => {
  const { userInfo } = store.getters
  // console.log(userInfo);

  let topicList = [
    `yxd/dev/web/admin/notice/unread/${userInfo.id}`,             // nav消息数量
    `yxd/dev/web/admin/member/recharge/success/${userInfo.id}`,   // 支付结果
    `yxd/dev/web/admin/member/banding/success/${userInfo.id}`,    // 成功绑定手机
    `yxd/dev/web/admin/member/invite/${userInfo.id}`,             // 邀请
  ]
  if (process.env.NODE_ENV === 'production') {
    topicList = [
      `yxd/prod/web/admin/notice/unread/${userInfo.id}`,              // nav消息数量
      `yxd/prod/web/admin/member/recharge/success/${userInfo.id}`,   // 支付结果
      `yxd/prod/web/admin/member/banding/success/${userInfo.id}`,    // 成功绑定手机
      `yxd/prod/web/admin/member/invite/${userInfo.id}`,             // 邀请
    ]
  }
  
  const mqtt = new HandleMQTT(global.mqttUrl, {
    topic: topicList,
    qos: 0
  }, { clientId: `web_admin_${userInfo.id}_${new Date().getTime()}` })

  mqtt.createConnect().then(res => {
    mqtt.getMessage((topic, message) => {
      if (message) {
        console.log('收到来着',topic,'的信息',message.toString())
        const { content } = JSON.parse(message.toString())
        switch(topic) {
          case topicList[0]:
            store.dispatch('setNavBadge', content)
            break;
          case topicList[1]:
            // console.log('充值topic');
            Notification({
              type: 'success',
              title: '充值成功',
              message: content,
              offset: 40
            })
            refreshUserInfo()
            break;
          case topicList[2]:
            // 成功绑定手机
            // const data = JSON.parse(message.toString())
            Notification({
              type: 'success',
              title: '绑定成功',
              message: '绑定成功手机号码绑定成功',
              offset: 40
            })
            refreshUserInfo()
            break;
          case topicList[3]:
            // 邀请
            break;
          default:
            return
        }
      }
    })
  })
}

export default initMQTT