/**
 * 用head方法请求，然后拿到响应字段里的etag去和前一次etag去比较。
 * 不支持同构渲染或者服务端渲染为主的内容推荐网站，因为刷新同一页面会有不同的文章推荐，所以html也不一样。这种情况下用etag来判断就不行了。
 */
export class Updater {

  constructor() {
    // 用于记录时间戳的变量，时间戳是响应头中的etag和last-modified字段其中之一
    this.previousTimeTag = null
  }

  // 记录首次请求的时间戳，以便与后面轮询得出的时间戳进行对比
  async init() {
    this.previousTimeTag = await this.getTimeTag()
    window.versionMonitor && clearInterval(window.versionMonitor)
    
    window.versionMonitor = setInterval(() => {
      // 开启轮询执行judge函数
      this.judge()
    }, 1000)
  }

  async getTimeTag() {
    const response = await fetch(`${window.location.protocol}//${window.location.host}`, {
      method: 'HEAD',
      cache: 'no-cache'
    })
    // 以响应体的etag或者last-modified为时间戳
    // console.log('response', response);
    return response.headers.get('etag') || response.headers.get('last-modified')
  }

  async judge() {
    // 获取当前最新的时间戳
    const currentTime = await this.getTimeTag()
    // 检测到最新请求的时间戳和上一次不一致，即文件发生变化
    if (this.previousTimeTag !== currentTime) {
      console.log('更新了');
    } else {
      console.log('未更新');
    }
  }
}

/**
 * 根据打完包之后生成的script src 的hash值去判断，每次打包都会生成唯一的hash值，只要轮询去判断不一样了，那一定是重新部署了.
 */
export class hashUpdater {
  
  constructor(options) {
    this.oldScript = [] //存储第一次值也就是script 的hash 信息
    this.newScript = [] //获取新的值 也就是新的script 的hash信息
    this.dispatch = {}  //小型发布订阅通知用户更新了
    this.init()
    this.timing(options?.time)
  }

  async init() {
    window.versionMonitor && clearInterval(window.versionMonitor)
    const html = await this.getHtml()
    this.oldScript = this.parserScript(html)
  }

  async getHtml() {
    const html = await fetch('/').then(res => res.text()) //读取index html
    return html
  }

  parserScript(html) {
    const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/ig) //script正则
    return html.match(reg)
  }

  on(key, fn) {
    if(!this.dispatch[key]) {
      this.dispatch[key] = []
    }
    this.dispatch[key].push(fn)
  }

  compare(oldArr, newArr) {
    const base = oldArr.length
    const arr = Array.from(new Set(oldArr.concat(newArr)))
    // 如果新旧length长度一样，既无更新
    if(base === arr.length) {
      this.dispatch['no-update'].forEach(fn => fn())
    } else {
      this.dispatch['update'].forEach(fn => fn())
    }
  }

  timing(time = 60 * 1000) {
    window.versionMonitor = setInterval(async () => {
      const newHtml = await this.getHtml()
      this.newScript = this.parserScript(newHtml)
      this.compare(this.oldScript, this.newScript)
    }, time)
  }
}
