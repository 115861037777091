<template>
	<div class="app-wrapper" :class="classObj">
		<Sidebar class="sidebar-container"></Sidebar>
		<div class="main-container">
			<Navbar></Navbar>
			<app-main></app-main>
		</div>
		<bind-telephone></bind-telephone>
	</div>
</template>

<script>
import Sidebar from './components/Sidebar/index.vue'
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'
import ResizeMixin from './mixin/ResizeHandler'
import bindTelephone from '@/components/bindTelephone/bindTelephone.vue'

export default {
	data () {
		return {

		}
	},
	mounted () {
	},
	mixins: [ResizeMixin],
	computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
		classObj() {
			return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
		}
	},
	components: {
		Sidebar,
		Navbar,
		AppMain,
		bindTelephone
	}
}
</script>

<style lang="scss" scoped>
  .app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
  }
</style>
