import { getInviteNotifyInfo } from '@/api/user/notice'
import { agreedBeMember } from '@/api/user/index'
import { MessageBox } from 'element-ui'

/**
 * 处理消息类型
 * @param {Number} typeid 消息类型ID
 * @param {Number} relationId 关联ID（邀请ID， 留言ID）
 * @returns Promise
 */
const notifyTypeMap = (typeid, relationId) => {
  return new Promise((resolve, reject) => {
    switch(typeid) {
      case 1:
        // 充值通知
        resolve(1)
        break;
      case 2:
        resolve(2)
        // 权益通知
        break;
      case 3:
        // 系统通知
        resolve(3)
        break;
      case 4:
        // 邀请成为下级用户
        getInviteNotifyInfo(relationId).then(res => {
          const { title, content, codeNumber } = res.data
          MessageBox.confirm(content, {
            title,
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                agreedBeMember({ codeNumber }).then(res => {
                  done();
                  resolve(4)
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                }).catch(err => {
                  instance.confirmButtonText = '确定';
                  instance.confirmButtonLoading = false;
                  reject({ typeid: 4, message: err })
                })
              } else {
                done();
              }
            }
          })
        })
        break;
      case 5:
        // 收到留言回复
        resolve(5)
        break;
      default:
        resolve(0)
        console.log('未知类型');
    }
  })
}

export default notifyTypeMap