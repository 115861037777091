import router from './router'
import store from './store'
// import NProgress from 'nprogress' // Progress 进度条
// import 'nprogress/nprogress.css'// Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权
import { Message } from 'element-ui'
import VueRouter from 'vue-router'
import initMQTT from '@/utils/initMQTT.js'

// 通过重写VueRouter原型对象上的push方法, 覆盖原来抛出异常的方法, "吞掉"异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const whiteList = ['/login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  // NProgress.start()
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
      // NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(res => {
          // 初始化mqtt
          initMQTT()
          
          let { menus, username, permission } = res.data
          
          // 获取权限许可列表
          let list = []
          if (permission && permission instanceof Array && permission.length > 0) {
            list = permission.map(element => element.value)
          }
          store.dispatch('setPermissionList', list)

          // 初始化动态路由
          store.dispatch('GenerateRoutes', { menus, username }).then(() => {
            // router.addRoutes(store.getters.addRouters)     // addRoutes 即将淘汰
            store.getters.addRouters.forEach(routerItem => {
              router.addRoute(routerItem)
            })
            // console.log('to', {...to});
            next({ ...to, replace: true })
            // router.push({path: to.fullPath})
          })
          
        }).catch(err => {
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
      
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      // NProgress.done()
    }
  }
})

// router.afterEach(() => {
//   NProgress.done() // 结束Progress
// })
