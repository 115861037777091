// date.js
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

export function str2Date(dateStr, separator) {
  if (!separator) {
    separator = "-";
  }
  let dateArr = dateStr.split(separator);
  let year = parseInt(dateArr[0]);
  let month;
  //处理月份为04这样的情况
  if (dateArr[1].indexOf("0") == 0) {
    month = parseInt(dateArr[1].substring(1));
  } else {
    month = parseInt(dateArr[1]);
  }
  let day = parseInt(dateArr[2]);
  let date = new Date(year, month - 1, day);
  return date;
}

/**
 * 用于下拉框计算日期
 * @param { Number } type 
 * 日期类型: 1 -> 当天, 2 -> 本月, 3 -> 本季, 4 -> 今年， 5 -> 前7天, 6 -> 前30天
 * @param { String } format 日期格式
 */
const dateTypeList = [1, 2, 3, 4, 5, 6]
export const dateOfSelect = (type, format = 'yyyy/MM/dd hh:mm:ss') => {
  const timeAttr = { startDate: null, endDate: null }

  if (!dateTypeList.includes(type)) return timeAttr
  
  
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()

  // 前X天，因为包含今天，所以x-1天
  const theFirstDay = {
    5: 7 - 1,
    6: 30 - 1
  }
  const getGapDay = () => {
    const dayStart = new Date(year, month, date.getDate() - theFirstDay[type]).setHours(0, 0, 0)
    const start = formatDate(new Date(dayStart), format)
    const dayEnd = new Date(date.setHours(23, 59, 59))
    const end = formatDate(dayEnd, format)
    timeAttr.startDate = start
    timeAttr.endDate = end
  }

  const map = {
    1: () => {
      const start = formatDate(new Date(date.setHours(0, 0, 0)), format);
      const end = formatDate(new Date(date.setHours(23, 59, 59)), format);
      timeAttr.startDate = start
      timeAttr.endDate = end
    },
    2: () => {
      const monthStart = new Date(year, month, 1).setHours(0, 0, 0)
      const monthEnd = new Date(year, month + 1, 0).setHours(23, 59, 59)
      const start = formatDate(new Date(monthStart), format)
      let end = formatDate(new Date(monthEnd), format)
      timeAttr.startDate = start
      timeAttr.endDate = end
    },
    3: () => {
      const quarter = Math.floor(month / 3)
      const quarterStart = new Date(year, quarter * 3, 1).setHours(0, 0, 0)
      const quarterEnd = new Date(year, (quarter + 1) * 3, 0).setHours(23, 59, 59)
      const start = formatDate(new Date(quarterStart), format)
      const end = formatDate(new Date(quarterEnd), format)
      timeAttr.startDate = start
      timeAttr.endDate = end
    },
    4: () => {
      const yearStart = new Date(year, 0, 1).setHours(0, 0, 0)
      const yearEnd = new Date(year, 12, 0).setHours(23, 59, 59)
      const start = formatDate(new Date(yearStart), format)
      const end = formatDate(new Date(yearEnd), format)
      timeAttr.startDate = start
      timeAttr.endDate = end
    },
    5: getGapDay,
    6: getGapDay
  }
  map[type]()

  return timeAttr
}


/**
 * 处理富文本里的图片宽度自适应
 * 1.去掉img标签里的style、width、height属性
 * 2.img标签添加style属性：max-width:100%;height:auto
 * 3.修改所有style里的width属性为max-width:100%
 * 4.去掉<br/>标签
 * @param html
 * @returns {void|string|*}
 */
export function formatRichText(html, viewWidth){
  let newContent= html.replace(/<img[^>]*>/gi,function(match,capture){
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      return match;
  });
  newContent = newContent.replace(/style="[^"]+"/gi,function(match,capture){
      match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
      return match;
  });
  newContent = newContent.replace(/<br[^>]*\/>/gi, '');
  newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"');

  viewWidth = viewWidth || 375
  newContent.replace(/<video[^>]*>/gi, (match) => {
		// 兼容旧版本后台上传的视频，默认16：9
		let reg = new RegExp(`controls="controls"`, 'g')
		newContent = newContent.replace(reg, `style="height: ${viewWidth * 0.5625}px;display:block;" controls`)
    return match.replace(/data-scale="[^"]+"/gi, (item) => {

			let tmp = item.split('=')[1].replace(/\"/g, '')
			// 通过比例变量重新计算视频高度
			let reg = new RegExp(`data-scale="${tmp}"`, 'g')
			newContent = newContent.replace(reg, `style="height: ${tmp ? viewWidth * tmp : viewWidth * 0.5625}px;display:block;"`)

			return item
    })
	})
	
  return newContent;
}