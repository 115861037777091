import Vue from "vue"
/**
 * 递归的方式实现深拷贝
 * @param Object obj
 * @returns new Object
 */
export const deepClone = (obj) => {
  const objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object' && obj !== null) {
    // 判断obj存在并且是对象类型的时候，因为null也是object类型，所以要单独做判断
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // 判断obj中是否存在key属性
        if (obj[key] && typeof obj[key] === 'object') {
          // 判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
          objClone[key] = deepClone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
