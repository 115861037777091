<template>
  <div class="drawer">
    <el-drawer
      title="通知列表"
      :visible.sync="drawer"
      :direction="direction"
      size="420px"
      @closed="handleClose">
      
      <easy-scroll @query="load" ref="ezScroll" v-model="list" :default-page-size="10" v-if="drawer">
        <template v-slot:default>
          <ul class="list">
            <li v-for="(item, index) in list" :key="index" :class="{'list-item': true, 'read': item.ifRead}">
              <!-- <router-link :to="`/notice?noticeId=${item.id}`" class="notice-item" @click.native="onClick(item)">
                <div class="nav">
                  <span>{{ item.title }}</span>
                  <p>{{ item.createDt | formatDateTime }}</p>
                </div>
                <p class="content">
                  {{ item.content }}
                </p>
              </router-link> -->
              <a class="notice-item" @click="onClick(item)">
                <div class="nav">
                  <span>{{ item.title }}</span>
                  <p>{{ item.createDt | formatDateTime }}</p>
                </div>
                <p class="content">
                  {{ item.content }}
                </p>
                <!-- <div class="link" v-if="index !== 2 && index !== 1">
                  前往查看 >
                </div> -->
              </a>
            </li>
          </ul>
        </template>
      </easy-scroll>

    </el-drawer>

    <message-preview :dialogVisible.sync="dialogVisible" :noticeId="noticeId"></message-preview>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import easyScroll from '@/components/easyScroll/index.vue'
import { unReadList, notifyRead } from '@/api/user/notice'
import { formatDate } from '@/utils/date'
import notifyTypeMap from '@/views/user/components/notifyTypeMap'
import messagePreview from '@/views/nms/components/messagePreview.vue'

export default {
  data() {
    return {
      direction: 'rtl',
      drawer: true,
      list: [],
      dialogVisible: false,
      noticeId: null
    }
  },
  computed: {
    ...mapGetters(['navbarNotice']),
  },
  watch: {
    navbarNotice: {
      handler(newVal) {
        this.drawer = newVal
      },
      immediate: true
    }
  },
  filters: {
    formatDateTime (value) {
      if (!value) return 'N/A'
      let date = new Date(value)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['GetInfo']),
    handleClose() {
      this.$store.dispatch('toggleNavbarNotice', false)
    },
    load (pageNo, pageSize) {
      const scroll = this.$refs.ezScroll
      unReadList().then(res => {
        let { list, total } = res.data
        scroll.completeByTotal(list, total)
      }).catch(() => scroll.completeByTotal([], 0))
    },
    onClick(item) {
      let { id, notifyTypeId, businessId } = item
      if (!item.ifRead) notifyRead({ id })

      notifyTypeMap(notifyTypeId, id).then(res => {
        switch(res) {
          case 1:
            // 充值通知
            // break;
          case 2:
            // 权益通知
            // break;
          case 3:
            // 系统通知
            // this.drawer = false
            // this.$nextTick(() => {
            //   this.$router.push({
            //     path: `/nms/notice`,
            //     query: {
            //       noticeId: id
            //     }
            //   })
            // })
            this.noticeId = id
            this.dialogVisible = true
            break;
          case 4:
            // 邀请成为下级用户
            this.GetInfo()
            this.$message.success('操作成功')
            break;
          case 5:
            // 收到留言回复
            this.drawer = false
            this.$nextTick(() => {
              this.$router.push({
                path: `/feedback/message`,
                query: {
                  messageId: businessId
                }
              })
            })
            break;
        }
      })

    }
  },
  components: { easyScroll, messagePreview }
}
</script>

<style lang="scss" scoped>
  :deep(.el-drawer__header span) {
    font-size: 20px;
  }
  .list {
    li {
      &.read {
        .notice-item {
          .nav, .content {
            color: #a6a6a6;
          }
        }
      }
      overflow: hidden;
      &:last-child {
        margin-bottom: 10px;
        .notice-item {
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: #ced2d9;
          }
        }
      }
      .notice-item {
        display: block;
        cursor: pointer;
        // margin: 8px 10px;
        // background-color: rgb(242,243,245);
        border-radius: 5px;
        padding: 15px 10px;
        box-sizing: border-box;
        font-size: 14px;
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 1px;
          background-color: #ced2d9;
        }

        .nav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: gray;
          margin-bottom: 10px;
        }
        .content {
          font-size: 16px;
          text-align: left;
          line-height: 1.7;
          color: #383838;
        }
        .link {
          border-top: 1px solid #ced2d9;
          padding: 7px 0 0;
          margin-top: 10px;
        }
      }
    }
  }
</style>
