import request from '../index';

// 消息列表
export const listNotify = (params) => {
  return request({
    url: '/manager/notify/list',
    method: 'GET',
    params
  })
}

// 已阅
export const notifyRead = (params) => {
  return request({
    url: '/manager/notify/read',
    method: 'POST',
    params
  })
}

// 未读列表
export const unReadList = () => {
  return request({
    url: '/manager/notify/unReadList',
    method: 'GET'
  })
}

// 获取通知详情
export const getNotifyInfo = (id) => {
  return request({
    url: '/manager/notify/getNotifyInfo/' + id,
    method: 'GET'
  })
}

// 通知类型为邀请的情况，获取通知详情
export const getInviteNotifyInfo = (id) => {
  return request({
    url: '/manager/notify/getInviteNotifyInfo/' + id,
    method: 'GET'
  })
}
