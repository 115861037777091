<template>
  <div class="app-main">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    name: 'AppMain'
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
