<template>
  <div class="sub-item">

    <template v-for="item in routes">
      <!-- 如果根菜单不显示 -->
      <template v-if="item.hidden"></template>
      <template v-else>
        <!-- 如果没有下级菜单 -->
        <router-link
          :to="item.path + '/' + item.children[0].path"
          :key="item.redirect"
          v-if="notHasChildrenShow(item.children) && !item.children[0].children">
          <el-menu-item :index="item.path + '/' + item.children[0].path" class="submenu-title-noDropdown">
            <svg-icon
              v-if="item.children[0].meta&&item.children[0].meta.icon"
              :icon-class="item.children[0].meta.icon">
            </svg-icon>
            <span v-if="item.children[0].meta&&item.children[0].meta.title">{{ item.children[0].meta.title }}</span>
          </el-menu-item>
        </router-link>

        <!-- 如果有子菜单 -->
        <el-submenu
          v-else
          :index="item.path"
          :key="item.path">
            
            <template slot="title">
              <svg-icon
                v-if="item.meta&&item.meta.icon"
                :icon-class="item.meta.icon">
              </svg-icon>
              <span v-if="item.meta&&item.meta.title">{{ item.meta.title }}</span>
            </template>

            <template v-for="sub in item.children">
              <!-- 如果该子元素不显示 -->
              <template v-if="sub.hidden"></template>
              <router-link v-else :to="item.path + '/' + sub.path" :key="sub.name">
                <el-menu-item :index="item.path + '/' + sub.path">
                  <svg-icon
                    v-if="sub.meta && sub.meta.icon"
                    :icon-class="sub.meta.icon">
                  </svg-icon>
                  <span v-if="sub.meta && sub.meta.title">{{ sub.meta.title }}</span>
                </el-menu-item>
              </router-link>
            </template>

        </el-submenu>
      </template>
    </template>

  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/index.vue'

export default {
  name: 'SidebarItem',
  data () {
    return {}
  },
  props: {
    routes: {
      type: Array,
      require: true
    }
  },
  components: {
    SvgIcon
  },
  methods: {
    notHasChildrenShow(children) {
      const showingChildren = children.filter(item => {
        return item.hidden !== true
      })
      if (showingChildren.length === 1) return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  // span {
  //   margin-left: 16px;
  // }
</style>
