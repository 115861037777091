import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/Layout.vue'

Vue.use(VueRouter)

// 如果“hidden:true”不会显示在侧边栏中（默认为false）

export const constantRouterMap = [{
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('@/views/login/Login.vue')
  }, {
    path: '/404',
    name: '404',
    hidden: true,
    component: () => import('@/views/404.vue')
  }, {
    path: '',
    redirect: '/home',
    component: Layout,
    children: [{
      path: 'home',
      name: 'home',
      meta: {title: '首页', icon: 'home'},
      component: () => import('@/views/home/Home.vue')
    }, {
      path: 'user',
      name: 'user',
      hidden: true,
      component: () => import('@/views/user/user.vue')
    }]
  }
]

export const asyncRouterMap = [{
  path: '/pms',
  redirect: '/pms/product',
  name: 'pms',
  // component: Layout,
  component: () => import('@/views/layout/Layout.vue'),
  meta: {title: '商品', icon: 'product'},
  children: [{
    path: 'product',
    name: 'product',
    meta: {title: '商品列表', icon: 'product-list'},
    component: () => import('@/views/pms/product/product.vue')
  }, {
    path: 'addProduct',
    name: 'addProduct',
    meta: {title: '添加商品', icon: 'product-add'},
    component: () => import('@/views/pms/product/add.vue')
  }, {
    path: 'updateProduct',
    name: 'updateProduct',
    meta: {title: '编辑商品'},
    hidden: true,
    component: () => import('@/views/pms/product/update.vue')
  }, {
    path: 'viewProduct',
    name: 'viewProduct',
    meta: {title: '查看商品', icon: 'product-list'},
    component: () => import('@/views/pms/product/check.vue')
  }, {
    path: 'tag',
    name: 'tag',
    meta: {title: '标签列表', icon: 'product-list'},
    component: () => import('@/views/pms/tag/tag.vue')
  }, {
    path: 'rule',
    name: 'rule',
    meta: { title: '商品规格', icon: 'product-list' },
    component: () => import('@/views/pms/rule/rule.vue')
  }]
}, {
  path: '/ums',
  redirect: '/ums/admin',
  name: 'ums',
  component: () => import('@/views/layout/Layout.vue'),
  meta: {title: '权限', icon: 'ums'},
  children: [{
    path: 'admin',
    name: 'admin',
    meta: {title: '用户列表', icon: 'ums-admin'},
    component: () => import('@/views/ums/admin/admin.vue')
  }, {
    path: 'role',
    name: 'role',
    meta: {title: '角色列表', icon: 'ums-role'},
    component: () => import('@/views/ums/role/role.vue')
  }, {
    path: 'allocMenu',
    name: 'allocMenu',
    meta: { title: '分配菜单' },
    component: () => import('@/views/ums/role/allocMenu.vue'),
    hidden: true
  }, {
    path: 'allocResource',
    name: 'allocResource',
    meta: { title: '分配资源' },
    component: () => import('@/views/ums/role/allocResource.vue'),
    hidden: true
  }, {
    path: 'menu',
    name: 'menu',
    meta: {title: '菜单列表', icon: 'ums-menu'},
    component: () => import('@/views/ums/menu/menu.vue')
  }, {
    path: 'addMenu',
    name: 'addMenu',
    meta: { title: '添加菜单' },
    component: () => import('@/views/ums/menu/add.vue'),
    hidden: true
  }, {
    path: 'updateMenu',
    name: 'updateMenu',
    meta: { title: '修改菜单' },
    component: () => import('@/views/ums/menu/update.vue'),
    hidden: true
  }, {
    path: 'resource',
    name: 'resource',
    meta: {title: '资源列表', icon: 'ums-resource'},
    component: () => import('@/views/ums/resource/resource.vue')
  }, {
    path: 'resourceCategory',
    name: 'resourceCategory',
    meta: { title: '资源分类' },
    component: () => import('@/views/ums/resource/resourceCategory.vue'),
    hidden: true
  }, {
    path: 'node',
    name: 'ums-node',
    meta: { title: '权限管理', icon: 'ums-menu' },
    component: () => import('@/views/ums/node/node.vue')
  }, {
    path: 'allocNode',
    name: 'allocNode',
    meta: { title: '分配权限' },
    hidden: true,
    component: () => import('@/views/ums/node/allocNode.vue')
  }]
}, {
  path: '/offer',
  redirect: '/offer/auth',
  name: 'miniProgram',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '报价小程序', icon: 'home' },
  children: [{
    path: 'auth',
    name: 'maintain',
    meta: { title: '授权码列表', icon: 'ums-menu' },
    component: () => import('@/views/offer/auth/auth.vue')
  }, {
    path: 'authAdd',
    name: 'quotation',
    meta: { title: '新增授权码', icon: 'product-add' },
    component: () => import('@/views/offer/auth/add.vue')
  }, {
    path: 'authUpdate',
    name: 'authUpdate',
    meta: { title: '编辑授权码' },
    hidden: true,
    component: () => import('@/views/offer/auth/update.vue')
  }, {
    path: 'behavior',
    name: 'behavior',
    meta: { title: '用户行为分析', icon: 'behavior' },
    component: () => import('@/views/offer/behavior/behavior.vue')
  }, {
    path: 'miniLogo',
    name: 'miniLogo',
    meta: { title: 'Logo配置', icon: 'miniLogo' },
    component: () => import('@/views/offer/miniLogo/miniLogo.vue')
  }, {
    path: 'miniBanner',
    name: 'miniBanner',
    meta: { title: 'Banner配置', icon: 'appBanner' },
    component: () => import('@/views/offer/miniBanner/miniBanner.vue')
  }, {
    path: 'addMiniBanner',
    name: 'addMiniBanner',
    meta: { title: '添加Banner' },
    hidden: true,
    component: () => import('@/views/offer/miniBanner/add.vue')
  }, {
    path: 'updateMiniBanner',
    name: 'updateMiniBanner',
    meta: { title: '编辑Banner' },
    hidden: true,
    component: () => import('@/views/offer/miniBanner/update.vue')
  }, {
    path: 'softPaper',
    name: 'softPaper',
    meta: { title: '小程序软文', icon: 'soft-paper' },
    component: () => import('@/views/offer/softPaper/softPaper.vue')
  }, {
    path: 'addSoftPaper',
    name: 'addSoftPaper',
    meta: { title: '添加软文' },
    hidden: true,
    component: () => import('@/views/offer/softPaper/add.vue')
  }, {
    path: 'updateSoftPaper',
    name: 'updateSoftPaper',
    meta: { title: '编辑软文' },
    hidden: true,
    component: () => import('@/views/offer/softPaper/update.vue')
  }]
}, {
  path: '/home-config',
  redirect: '/home-config/index',
  name: 'home-config',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '首页配置', icon: 'home-info' },
  children: [{
    path: 'index',
    name: 'home-index',
    meta: { title: '指标设置', icon: 'home-index' },
    component: () => import('@/views/home/index/index.vue')
  }, {
    path: 'target',
    name: 'target',
    meta: { title: '目标设置', icon: 'home-target' },
    component: () => import('@/views/home/target/target.vue')
  }, {
    path: 'module',
    name: 'module',
    meta: { title: '模块分类' },
    hidden: true,
    component: () => import('@/views/home/module/module.vue')
  }]
}, {
  path: '/help',
  redirect: '/help/index',
  name: 'help',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '帮助', icon: 'help' },
  children: [{
    path: 'index',
    name: 'help-index',
    meta: { title: '帮助文档', icon: 'help' },
    component: () => import('@/views/help/index.vue')
  }, {
    path: 'add',
    name: 'help-add',
    meta: { title: '添加帮助文档' },
    hidden: true,
    component: () => import('@/views/help/add.vue')
  }]
}, {
  path: '/feedback',
  redirect: '/feedback/index',
  name: 'feedback',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '用户反馈', icon: 'help' },
  children: [{
    path: 'message',
    name: 'message',
    meta: { title: '我要留言', icon: 'help' },
    component: () => import('@/views/feedback/message/message.vue')
  }]
}, {
  path: '/ams',
  redirect: '/ams/list',
  name: 'ams',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '活动管理', icon: 'oms-promotion' },
  children: [{
    path: 'list',
    name: 'ams-list',
    meta: { title: '活动列表', icon: 'ams-list' },
    component: () => import('@/views/ams/list/promotion.vue')
  }, {
    path: 'addPromotion',
    name: 'addPromotion',
    meta: {title: '添加销售活动'},
    hidden: true,
    component: () => import('@/views/ams/list/add.vue')
  }, {
    path: 'updatePromotion',
    name: 'updatePromotion',
    meta: {title: '编辑销售活动'},
    hidden: true,
    component: () => import('@/views/ams/list/update.vue')
  }]
}, {
  path: '/nms',
  redirect: '/nms/notice',
  name: 'nms',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '通知管理', icon: 'notice' },
  children: [{
    path: 'notice',
    name: 'notice',
    meta: { title: '消息列表', icon: 'notice' },
    component: () => import('@/views/nms/notice.vue')
  }]
}, {
  path: '/mms',
  redirect: '/mms/vip',
  name: 'mms',
  component: () => import('@/views/layout/Layout.vue'),
  meta: { title: '会员特权', icon: 'vip-2' },
  children: [{
    path: 'vip',
    name: 'vip',
    meta: { title: '开通会员', icon: 'vip' },
    component: () => import('@/views/mms/vip/vip.vue')
  }, {
    path: 'record',
    name: 'record',
    meta: { title: '购买记录', icon: 'record' },
    component: () => import('@/views/mms/record/record.vue')
  }, {
    path: 'foresee',
    name: 'foresee',
    meta: { title: '功能预告', icon: 'func-perview' },
    component: () => import('@/views/mms/foresee/foresee.vue')
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}]

const createRouter = () => new VueRouter({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRouterMap
})

const router = createRouter()

export const resetRouter = () => {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
