import {
  login, logout, info, getPersonal,
  loginWithWechat, loginWithVCode
} from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth.js'
import { Notification } from 'element-ui'

const user = {
  // namespaced: true,
  state: {
    token: getToken(),
    // name: '',           // nickName -> 中文名/昵称
    // username: '',       // 登录账户 -> 字母或者数字
    // avatar: '',
    roles: [],
    // userId: null,
    adminFlag: null,
    // vipInfo: {
    //   levelName: null,
    //   levelIcon: null,
    //   levelId: null
    // },
    userInfo: {},
    loginMethod: null
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    // SET_NAME (state, name) {
    //   state.name = name
    // },
    // SET_USERNAME(state, name) {
    //   state.username = name
    // },
    // SET_AVATAR (state, avatar) {
    //   state.avatar = avatar
    // },
    SET_ROLES (state, roles) {
      state.roles = roles
    },
    // SET_ID (state, id) {
    //   state.userId = id
    // },
    SET_ADMIN_FLAG (state, data) {
      state.adminFlag = data
    },
    // SET_VIP_INFO (state, data) {
    //   state.vipInfo = data
    // },
    SET_USER_INFO(state, data) {
      state.userInfo = data
    },
    SET_LOGIN_METHOD(state, data) {
      state.loginMethod = data
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          const data = res.data
          const tokenStr = data.tokenHead+data.token
          setToken(tokenStr)
          commit('SET_TOKEN', tokenStr)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    wechatLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginWithWechat(data).then(res => {
          const data = res.data
          const tokenStr = data.tokenHead+data.token
          setToken(tokenStr)
          commit('SET_TOKEN', tokenStr)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    loginWithPhone({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginWithVCode(data).then(res => {
          const data = res.data
          const tokenStr = data.tokenHead+data.token
          setToken(tokenStr)
          commit('SET_TOKEN', tokenStr)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(res => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端登出
    FedLogOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    GetInfo({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        info().then(res => {
          const data = res.data
          commit('SET_ROLES', data.roles)
          commit('SET_ADMIN_FLAG', data.adminFlag)
          getPersonal().then(result => {
            const { hasPhone, changePassword } = result.data
            commit('SET_USER_INFO', result.data)
            if (!hasPhone && state.loginMethod === 'wx') {
              // 未绑定手机号的账户，仅微信扫码登录后提示
              setTimeout(() => {
                dispatch('toggleBindCellPhone', true, { root: true })
                commit('SET_LOGIN_METHOD', null, { root: true })
              }, 500)
            } else if (changePassword && state.loginMethod === 'pwd') {
              // changePassword && state.loginMethod === 'pwd'
              // 没有修改初始密码的账号，仅密码登录后提示
              setTimeout(() => {
                Notification({
                  type: 'warning',
                  title: '修改密码',
                  message: '您当前为初始密码，为了您的账户安全，请立即修改密码!',
                  offset: 40
                })
                dispatch('toggleChgDefPwdDailog', true, { root: true })
                commit('SET_LOGIN_METHOD', null, { root: true })
              }, 500)
            }
            resolve(res)
          })
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
